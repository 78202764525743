import React, {lazy} from "react";
import {useSelector} from "react-redux";

export default function DialogsHandler() {
    // const globalImageEditor = useSelector(state => state.ui.globalImageEditor);
    // const ImageEditor = lazy(() => import('~/@newestapps/Components/ImageEditor/index.js'));

    return (
        <React.Suspense fallback={<React.Fragment />}>

           {/*<ImageEditor image={globalImageEditor} open={globalImageEditor}/>*/}

        </React.Suspense>
    );
}
