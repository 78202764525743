import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setBreadcrumb } from '~/@newestapps/Redux/actions/ui';

export default function BreadCrumb({ items = [], folder, extend = false, clear = true }) {
  const dispatch = useDispatch();

  const breads = useSelector(state => state.ui.breadcrumb);

  useEffect(() => {
    const bs = extend ? breads : [];

    if (folder) {
      bs.push({
        icon: 'folder-close',
        text: folder,
      });
    }

    items.forEach(i => {
      bs.push(i);
    });

    dispatch(setBreadcrumb(bs, extend, clear));
  }, []);

  return (
    <React.Fragment />
  );

}
