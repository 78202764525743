import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    min-width: 400px;
    max-width: 500px;
    padding: 17px;
    padding-bottom: 10px;
`;

export const Footer = styled.div`
    min-width: 400px;
    max-width: 500px;
    padding: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
