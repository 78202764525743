import React, {useEffect} from "react";
import { ThemeProvider } from "styled-components";
import {BrowserRouter} from "react-router-dom";
import {Provider, useDispatch, useSelector} from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Store, Persistor } from "~/@newestapps/Redux/Store";

import { loadTheme } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';

// Style
import GlobalStyle from "~/GlobalStyle.js";

import {Corporate as AppTheme} from "~/@newestapps/Theme";
import {SnackbarProvider, useSnackbar} from "notistack";
import {isEmpty} from "lodash";
import ACL from "~/@newestapps/ACL";
import {finishBoot, openDialog, setACL, setUser} from "~/@newestapps/Redux/actions/ui";

import {Base} from "~/@newestapps/Layout";
import Routes from "~/@newestapps/Routes";
import RoutesAuth from "~/@newestapps/RoutesAuth";

import AppBackendHandler from './BackendHandler';
import AppWebSocketHandler from "./WebsocketHandler";
import DialogsHandler from "./DialogsHandler";
import GlobalEvents from "~/GlobalEvents";

import {AcheiCorridas} from 'acheicorridas';

initializeIcons();
loadTheme(AppTheme.colors.fluentUI);

function AppBoot() {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {

        window.onunhandledrejection = (err) => {
            let exception;

            if (err && err.reason) {
                exception = err.reason;
            } else {
                exception = err;
            }

            if (exception && exception.response && exception.response.data) {
                if (exception.response.status) {

                    switch (exception.response.status) {
                        default:
                            if (!isEmpty(exception.response.data.message)) {
                                // enqueueSnackbar(exception.response.data.message, { variant: 'error' });
                                console.log("API ERROR => ", exception.response);
                            }
                            break;
                    }

                    if (ACL.allow('admin') && exception.response.data) {
                        if (exception.response.data.debug) {
                            enqueueSnackbar('DEBUG: ' + exception.response.data.debug, {variant: 'warning'});
                            console.log("DEBUG => ", exception.response.data);
                        }

                        if (exception.response.data.exception) {
                            enqueueSnackbar('EXCEPTION: ' + exception.response.data.exception, {variant: 'warning'});
                            console.log("EXCEPTION => ", exception.response.data);
                        }
                    } else {
                        if (exception.response.data) {

                            if (exception.response.data.exception) {
                                enqueueSnackbar('Ocorreu um problema interno ao processar sua solicitação! Por favor, entre em contato com o suporte.', {variant: 'error'});
                                console.log("EXCEPTION => ", exception.response.data);
                            }

                        }
                    }

                }
            }
        };

        // Api.listenResponseEvent('onUnauthenticated', (response, message) => {
        //     // dispatch(logout());
        //     dispatch(openDialog('loginDialog'));
        //     enqueueSnackbar('Sua sessão expirou!', {variant: 'info'});

        //     setTimeout(() => {
        //         dispatch(finishBoot());
        //     }, 100)
        // });

    }, []);

    return <React.Fragment/>
}

function AppContainer() {
    const dispatch = useDispatch();

    const accessToken = useSelector(state => state.preferences.accessToken);
    const user = useSelector(state => state.preferences.user);
    const booted = useSelector(state => state.ui.booted);

    if(accessToken && accessToken.access_token){
        AcheiCorridas.instance.getApi().setAccessToken(accessToken.access_token);
    }

    useEffect(() => {
        if (!isEmpty(accessToken) && !isEmpty(user)) {

            // Promise.all([
            //     Api.Users.Me(),
            //     Api.Users.ACL(),
            // ]).then(results => {
            //     const me = results[0].data.data;
            //     const acl = results[1].data.data;

            //     ACL.User = me;
            //     ACL.Permissions = acl;

            //     dispatch(setACL(ACL.Permissions));
            //     dispatch(setUser(ACL.User));

            dispatch(finishBoot());
            // });

        } else {
            dispatch(finishBoot());
        }
    }, []);

    useEffect(() => {
        if(accessToken && accessToken.access_token){
            AcheiCorridas.instance.getApi().setAccessToken(accessToken.access_token);
        }
    }, [accessToken]);

    return (
        <React.Fragment>
            <SnackbarProvider
                maxSnack={10}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <BrowserRouter>

                    {booted ? (
                        !isEmpty(accessToken) && !isEmpty(user) ? (
                            <Base>
                                <Routes/>
                                <GlobalEvents/>
                            </Base>
                        ) : (
                            <Base noAuth>
                                <RoutesAuth/>
                            </Base>
                        )
                    ) : (
                        <AppBoot/>
                    )}

                </BrowserRouter>

                <AppBackendHandler/>
                <AppWebSocketHandler/>
                <DialogsHandler />

            </SnackbarProvider>
        </React.Fragment>
    );
}

export default function App({children}) {
    const theme = AppTheme;

    return (
        <React.Fragment>
            <Provider store={Store}>
                <PersistGate persistor={Persistor} loading={null}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        {children}
                        <AppContainer/>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </React.Fragment>
    );
}
