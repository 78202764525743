import React from 'react';
import { RootLayout } from './styles';

import RoutesContainer from '../RoutesContainer';
import Header from '../Header';
import Content from '../Content';
import Footer from '../Footer';
import Sidebar from '../Sidebar';

export default function Base(props) {
  if (props.noAuth) {
    return (
      <RootLayout>
        <RoutesContainer>
          <Content style={{ paddingTop: 50, }}>
            {props.children}
          </Content>
        </RoutesContainer>
      </RootLayout>
    );
  }

  return (
    <RootLayout>
      <Sidebar />

      <RoutesContainer>
        <Header />

        <Content style={{ paddingTop: 50, }}>
          {props.children}
        </Content>

        <Footer />
      </RoutesContainer>
    </RootLayout>
  );
}
