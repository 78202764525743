import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import Api from "~/Api";
import {useSelector} from "react-redux";

import {setWebsocketConnected} from "~/@newestapps/Redux/actions/ui";

window.Pusher = Pusher;

let booted = false;

export default function AppWebSocketHandler() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.ui.user);
    const accessToken = useSelector(state => state.preferences.accessToken);

    useEffect(() => {
        if (accessToken && accessToken.access_token)
            if (user && user.id && !booted) {
                window.Echo = new Echo({
                    broadcaster: 'pusher',
                    key: '98564397843529845874687463',
                    wsHost: Api.websocketHOST, //window.location.hostname,
                    wsPort: 6001,
                    authEndpoint: Api.authURL + 'newestapps/broadcasting/auth',
                    disableStats: true,
                    auth: {headers: {Authorization: accessToken.token_type + ' ' + accessToken.access_token}}
                });

                dispatch(setWebsocketConnected(window.Echo));

                booted = true;
            }
    }, [user]);

    return (
        <React.Fragment>

        </React.Fragment>
    );
}
