import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    padding: 0px 30px;
    flex-direction: row;
    background: rgb(252,253,254);
    background: linear-gradient(180deg, rgba(252,253,254,1) 0%, rgba(245,247,249,1) 100%);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
    position: fixed;
    top: 50px;
    left: ${props => props.collapsed ? 49 : 230}px;
    right: 0;
    z-index: 14;
`;
