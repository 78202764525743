import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { RadioGroup, Radio } from "@blueprintjs/core";

let mValue;

export default function Component({ name, groupProps = {}, options = [], children }) {
  const inputRef = useRef();
  const { fieldName, registerField, defaultValue } = useField(name);

  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    mValue = defaultValue;

    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        return mValue;
      }
    });
  }, [fieldName, registerField]);

  const handleChange = e => {
    setVal(e.target.value);
    mValue = e.target.value;
  }

  return (
    <RadioGroup
      {...groupProps}
      onChange={handleChange}
      selectedValue={val}
    >
      {children ? (
        children
      ) : (
          options.map(o => (
            <Radio {...o} name={name} />
          ))
        )}
    </RadioGroup>
  );
}

// <Switch
//   name={name}
//   inputRef={ref => {
//     inputRef.current = ref;
//   }}
//   defaultChecked={defaultValue}
//   {...rest}
// />