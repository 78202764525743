import React from "react";
import styled from "styled-components";
import {random} from 'lodash';

import { Surface } from "~/Components/UI";

const bgTurn = random(1, 6);
const bgImage = bgTurn === 0 ? '/login/background.jpg' :  '/login/background_'+bgTurn+'.jpg' ;

export const Container = styled.div`
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Logo = styled.img.attrs(attrs => ({
  src: attrs.theme.logoOriginal,
  alt: "Newestapps"
}))`
  width: 340px;
  height: 70px;
  margin: 0 auto;
`;
