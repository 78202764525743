import React, {useEffect, useRef, useState} from "react";
import {useField} from "@unform/core";
import {Button, ControlGroup, Icon, InputGroup, MenuItem, Spinner} from "@blueprintjs/core";
import InputMask from 'react-input-mask';
import {useSnackbar} from "notistack";
import {isEmpty} from "lodash";
import {Select} from "@blueprintjs/select";

export const Presets = {
    // MONEY: ['R$ ', /[0-9]+/i, ',', /[0-9]{2}/i],
    CPF: '999.999.999-99',
    CNPJ: '99.999.999/9999-99',
    PHONE_NUMBER: '(99) 99999-9999',
    POSTAL_CODE: '99999-999',
};

export default function Component({name, type = "text", mask = [], options = [], optionsLayout = 'button', ...rest}) {
    const {enqueueSnackbar} = useSnackbar();
    const inputRef = useRef();
    const {fieldName, registerField, defaultValue, error} = useField(name);

    const [activeOption, setActiveOption] = useState(options.length > 0 ? options[0] : {});

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: "value",
            getValue: ref => {
                const ip = ref.current;

                return ip.value;
            }
        });
    }, [fieldName, registerField]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, {
                variant: 'warning',
            })
        }
    }, [error]);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [activeOption]);

    const MInputMask = (
        <InputGroup
            intent={error ? 'danger' : 'none'}
            name={name}
            type={type}
            inputRef={ref => {
                inputRef.current = ref;
            }}
        />
    );

    if (options.length >= 1 && optionsLayout === 'button') {
        return (
            <ControlGroup disabled={rest.loading} fill>
                {options.map(opt => (
                    <Button
                        active={activeOption.label === opt.label}
                        onClick={() => setActiveOption(opt)}
                        disabled={rest.loading}
                        {...rest.buttonProps}
                    >
                        {opt.label}
                    </Button>
                ))}

                <InputMask
                    mask={
                        (Presets[activeOption.mask] ? Presets[activeOption.mask] : activeOption.mask)
                    }
                    {...rest}
                    defaultValue={defaultValue}
                >
                    {MInputMask}
                </InputMask>
            </ControlGroup>
        );
    }


    if (options.length >= 1 && optionsLayout === 'select') {
        return (
            <ControlGroup disabled={rest.loading} fill>
                <Select
                    items={options}
                    popoverProps={{
                        modifiers: {
                            arrow: false,
                            preventOverflow: 'window',
                            usePortal: true,
                            position: 'bottom',
                        },
                        preventOverflow: 'window',
                        usePortal: true,
                        position: 'bottom',
                    }}
                    filterable={false}
                    fill={true}
                    scrollToActiveItem
                    itemRenderer={(item) => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    setActiveOption(item);
                                    setTimeout(() => {
                                        if (inputRef && inputRef.current)
                                            inputRef.current.focus();
                                    }, 100);
                                }}
                                active={activeOption.label === item.label}
                                text={item.label}
                                {...rest.selectItemProps}
                            />
                        );
                    }}
                    {...rest.selectProps}
                >
                    <Button
                        disabled={rest.loading}
                        fill
                        // intent={rest.intent ? rest.intent : (activeOption ? 'success' : 'none')}
                        rightIcon={'chevron-down'}
                    >
                        {activeOption.label}
                    </Button>
                </Select>

                <InputMask
                    mask={
                        (Presets[activeOption.mask] ? Presets[activeOption.mask] : activeOption.mask)
                    }
                    fill
                    {...rest}
                    defaultValue={defaultValue}
                >
                    {MInputMask}
                </InputMask>
            </ControlGroup>
        );
    }

    return (
        <InputMask mask={mask}
                   {...rest}
                   defaultValue={defaultValue}
        >
            {MInputMask}
        </InputMask>
    );
}
