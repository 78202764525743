import React, { Suspense } from 'react';
import { Container } from './styles';
import {useSelector} from "react-redux";

export default function RoutesContainer(props) {
    const sidebarCollapsed = useSelector(state => state.preferences.sidebarCollapsed);
    const sidebarCollapsedRequested = useSelector(state => state.ui.requestSidebarCollapsed);

    return (
        <Container collapsed={(sidebarCollapsedRequested === true || sidebarCollapsed)}>
            {props.children}
        </Container>
    );

}
