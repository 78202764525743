import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { InputGroup } from "@blueprintjs/core";

export default function Component({ name, type = "text", ...rest }) {
  const inputRef = useRef();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        const ip = ref.current;

        return ip.value;
      }
    });
  }, [fieldName, registerField]);

  return (
    <InputGroup
      intent={error ? 'danger': 'none'}
      name={name}
      type={type}
      inputRef={ref => {
        inputRef.current = ref;
      }}
      defaultValue={defaultValue}
      {...rest}
    />
  );
}
