import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { Button, MenuItem, Spinner, Icon } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import { debounce, snakeCase, isEmpty, trim } from 'lodash';

let cachedResults = [];

export default function Component({ name, type = "text", items = [], itemView, selectedView, selected, primaryKey = 'id', filterAttributes = ['name'],
  disabled, onQueryChange, loading = false, searchOnly, initialSearchTerm, pickerMode = false, onItemSelected, fill, selectIntent = 'none', loadDefaultValue, ...rest }) {

  const inputRef = useRef();
  const searchInputRef = useRef();

  const { fieldName, registerField, defaultValue, error } = useField(name);

  cachedResults = items;
  const [dataset, setDataSet] = useState(items);
  const [selectedItem, setSelectedItem] = useState(!selected ? (items && items.length > 0 ? items[0] : null) : selected);
  const [isContentLoading, setIsContentLoading] = useState(loading);
  const [searchQuery, setSearchQuery] = useState(initialSearchTerm);

  useEffect(() => {
    setDataSet(items);
  }, [items]);

  useEffect(() => {
    if (!pickerMode) {

      if (defaultValue && searchOnly) {
        if (loadDefaultValue) {
          loadDefaultValue(defaultValue, setSelectedItem);
        }

        return;
      }

      if (defaultValue && items) {
        for (let i = 0; i < items.length; i++) {
          if (String(defaultValue) === String(items[i][primaryKey])) {
            setSelectedItem(items[i]);
            break;
          }
        }
      }

    }
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        return ref.current.value;
      }
    });
  }, [fieldName, registerField]);

  const handleClick = (e, item, index) => {
    if (!pickerMode) {
      setSelectedItem(item);
    }

    if (onItemSelected) {
      onItemSelected(item, index);
    }
  };

  const handleQueryChange = (query) => {
    performSearch(query);
  };

  const isLoading = (l) => {
    setIsContentLoading(l);
  }

  const performSearch = useCallback(debounce((query) => {
    if (isEmpty(trim(query))) {
      setDataSet(cachedResults);
      return;
    }

    setSearchQuery(query);

    if (searchInputRef && searchInputRef.current) searchInputRef.current.focus();

    if (onQueryChange) {
      onQueryChange(query, isLoading);
      return;
    }

    query = snakeCase(query);

    if (items) {
      const results = items.filter(i => {

        for (let f = 0; f < filterAttributes.length; f++) {
          const field = filterAttributes[f];

          if (snakeCase(i[field]).indexOf(query) >= 0) {
            return i;
          }
        }
      });

      cachedResults = dataset;
      setDataSet(results);
    }
  }, 500));

  return (
    <React.Fragment>
      <input type="hidden" ref={inputRef} value={selectedItem ? selectedItem[primaryKey] : null} />

      <Select
        items={!isContentLoading ? dataset : []}
        popoverProps={{
          modifiers: {
            arrow: false,
            preventOverflow: 'window',
            usePortal: true,
            position: 'bottom',
          },
          preventOverflow: 'window',
          usePortal: true,
          position: 'bottom',
        }}
        fill={fill}
        filterable
        resetOnClose={!searchOnly}
        scrollToActiveItem
        onQueryChange={handleQueryChange}
        inputProps={{
          disabled: isContentLoading,
          placeholder: 'Pesquisar...',
          inputRef: searchInputRef,
          autoFocus: true,
        }}
        noResults={!isContentLoading ? (
          searchOnly && isEmpty(searchQuery) ? (
            <div style={{ padding: 20, textAlign: 'center', color: '#cccccc' }}><Icon icon={'inbox-search'} iconSize={40} /></div>
          ) : (
              <MenuItem disabled={true} text="Nenhum resultado." />
            )
        ) : (
            <div style={{ padding: 15, }}><Spinner size={25} /></div>
          )}
        itemRenderer={(item, { index }) => {
          const clickItem = (e) => { handleClick(e, item, index) };

          return (
            itemView ? (
              itemView(item, selectedItem ? (item[primaryKey] === selectedItem[primaryKey]) : false, clickItem)
            ) : (
                <MenuItem {...item} onClick={clickItem} />
              )
          );
        }}

        disabled={disabled}
        {...rest}
      >
        <Button disabled={disabled} fill={fill} icon={rest.icon} intent={rest.intent ? rest.intent : (!pickerMode && selectedView && selectedItem ? 'success' : selectIntent)} rightIcon={'chevron-down'}>
          {!pickerMode && selectedView && selectedItem ? (selectedView(selectedItem)) : rest.label}
        </Button>
      </Select>
    </React.Fragment>
  );
}
