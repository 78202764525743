import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {isEmpty, trim} from 'lodash';
import {useNavigate, useLocation} from 'react-router-dom';

import { showLoadingOverlay, hideLoadingOverlay, setACL, closeDialog } from "~/@newestapps/Redux/actions/ui";
import { savePreference } from "~/@newestapps/Redux/actions/preferences";

// Components
import { Text, Button, Spinner, Stack } from '@fluentui/react';
import { Logo } from "~/Components";

import {
  Container,
} from './styles';

import {SnackbarProvider, useSnackbar} from "notistack";

import {AcheiCorridas, Users} from 'acheicorridas';
import {Organizer} from 'acheicorridas-organizador';

export default function OAuthSigninScreen() {
  const formRef = React.useRef();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  const performError = () => {
    enqueueSnackbar('Ocorreu um erro ao realizar a autenticação, por favor tente novamente!', {variant: 'error'});
    navigate('/');
  }

  useEffect(() => {
    if(!isEmpty(trim(window.location.hash))){
      const hash = window.location.hash;

      if(hash.startsWith('#access_token')){
        window.location.hash = '#';

        const len = "#access_token=".length;
        const accessToken = trim(hash.substr(len, hash.length - len));

        AcheiCorridas.instance.getApi().setAccessToken(accessToken);
        
        dispatch(savePreference("accessToken", {access_token: accessToken, provider: "oauth2"}));
        dispatch(showLoadingOverlay("Bem Vindo!"));
        
        Promise.all([
          Users.include('organizer').Me(),
          // Users.PublicKey(),
          // Users.Roles(),
        ]).then(results => {

          const user = results[0];
          const organizer = results[1];
          // const publicKey = results[1];
          // const roles = results[2];

          console.log(user, organizer);

          dispatch(savePreference("user", user));
          dispatch(savePreference("organizer", organizer));

          navigate('/');
        }).catch(e => {
          console.log(e);
        })

        setTimeout(() => {window.location.hash='#oi,..tudo.bem?';}, 2000);
        setTimeout(() => {window.location.hash='#';}, 3500);
      }else{
        performError();
      }

    }else{
      performError();
    }
  }, []);

  return (
    <Container>

        <Stack
          tokens={{
            childrenGap: 70,
          }}
        >

          <Stack.Item align="center">
            <Logo login style={{height: 60,}} />
          </Stack.Item>

          <Stack.Item align="center" style={{width: 300,}}>
            <Spinner label="Aguarde..." labelPosition="right" />
          </Stack.Item>

          <Stack.Item align="center">
          
          </Stack.Item>

        </Stack>

    </Container>
  );
}


// <Container>
//       <Header>
//         <PageContent>
//           <Logo />
//         </PageContent>
//       </Header>

//       <PageContent centerHorizontal>
//         <LoginBox>
//           <H1>Entrar</H1>
//           <Hint pb={20}>
//             Informe seu login e senha de acesso que enviamos pra você
//           </Hint>

          // <FormControl>
          //   <Input
          //     name={"username"}
          //     label="CPF/CNPJ"
          //     value={login}
          //     maxLength={20}
          //     error={!isEmpty(error)}
          //     disabled={loading}
          //     onChange={e => {
          //       setLogin(e.target.value);
          //     }}
          //   />
          // </FormControl>

//           <FormControl style={{ marginTop: 15 }}>
//             <Input
//               name={"password"}
//               type="password"
//               label="SENHA DE ACESSO"
//               value={password}
//               maxLength={30}
//               error={!isEmpty(error)}
//               disabled={loading}
//               onChange={e => {
//                 setPassword(e.target.value);
//               }}
//             />
//           </FormControl>

//           <FormControl style={{ marginTop: 15 }}>
//             <Button onClick={signin} disabled={loading} loading={loading}>
//               Entrar
//             </Button>
//           </FormControl>
//         </LoginBox>
//       </PageContent>
//     </Container>
