export default class ACL {
  
  static Permissions;
  static User;

  static allow(action) {
    if(!ACL.Permissions || !ACL.Permissions[action]) return false;
    
    if(Array.isArray(action)) {
      if(action.length === 0) return true;
      
      for(let i = 0; i < action.length; i++){
        if(ACL.Permissions[action[i]] === false){
          return false;
        }
      }

      return true;
    }else{
      if(ACL.Permissions[action] === true){
        return true;
      }
    }

    return false;
  }

}
