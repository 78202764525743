import React from 'react';
import { Navbar } from '@blueprintjs/core';

export default function FixedActions(props) {
  const style = {};

  if (props.large) {
    style.height = 70;
    style.paddingTop = 10;
  }

  return (
    <Navbar style={{ position: 'fixed', bottom: 45, ...style, left: 230, right: 0, paddingRight: 85 }}>
      {props.children}
    </Navbar>
  );

}

