import axios from "axios";
import { isEmpty } from "lodash";
import qr from "query-string";

export const DEBUG =
  process &&
  process.env &&
  process.env.NODE_ENV &&
  process.env.NODE_ENV === "development";

export default class Api {

  static baseURL = DEBUG
    ? "http://newestapps-base-backend.test2/api/"
    : "https://api.garshop.bedinfoservices.com.br/"; // Production
  static authURL = DEBUG
    ? "http://newestapps-base-backend.test2/"
    : "https://api.garshop.bedinfoservices.com.br/"; // Production
  static websocketHOST = DEBUG
    ? "127.0.0.1"
    : "nw01-sockets.newestapps.net";

  static client = DEBUG ? "2" : "78626";
  static secret = DEBUG ? "YJDUlniCt3zWtAMNvuie0oyOXOTbQEfMkq3QTmYe" : "X9MtLVzwLgzArpdzCBu5DF7nV9jsSM3xkWzfS8lBhMRxw5si";

  static instance;
  static accessToken;

  static responseEvents = {};
  static requestEvents = {};

  static setAccessToken(token) {
    if (!isEmpty(token)) {
      Api.accessToken = token;
      Api.instance = axios.create({
        baseURL: Api.baseURL,
        timeout: 10000,
        headers: {
          Accept: "application/json",
          Authorization: token.token_type + " " + token.access_token,
        },
      });

      Api.interceptRequestEvents();
      Api.interceptResponseEvents();
    }
  }

  static getInstance() {
    if (!Api.instance) {
      Api.instance = axios.create({
        baseURL: Api.baseURL,
        timeout: 10000,
        headers: {
          Accept: "application/json",
        },
      });

      Api.interceptRequestEvents();
      Api.interceptResponseEvents();
    }

    return Api.instance;
  }

  static interceptRequestEvents() {
    Api.instance.interceptors.request.use(
      (config) => {
        if (!Api.requestEvents) {
          return config;
        }

        if (config.data instanceof FormData) {
          config.onUploadProgress = (progressEvent) => {
            console.log(progressEvent);

            if (Api.requestEvents.onUploadProgress) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              Api.requestEvents.onUploadProgress(
                config,
                progressEvent,
                percentCompleted
              );
            }
          };
        }

        // /** In dev, intercepts request and logs it into console for dev */
        if (DEBUG) {
          console.info("✉️ ", config);
        }
        return config;
      },
      (error) => {
        if (DEBUG) {
          console.error("✉️ ", error);
        }
        return Promise.reject(error);
      }
    );
  }

  static interceptResponseEvents() {
    Api.instance.interceptors.response.use(
      (response) => {
        return response;
      },

      (error) => {
        if (Api.responseEvents && error.response) {
          const r = error.response;
          const message = r.data.message;

          if (r.status) {
            switch (r.status) {
              case 400:
                if (Api.responseEvents.onBadRequest)
                  Api.responseEvents.onBadRequest(r, message);
                break;
              case 401:
                if (Api.responseEvents.onUnauthenticated)
                  Api.responseEvents.onUnauthenticated(r, message);
                break;
              case 403:
                if (Api.responseEvents.onUnauthorized)
                  Api.responseEvents.onUnauthorized(r, message);
                break;
              case 404:
                if (Api.responseEvents.onNotFound)
                  Api.responseEvents.onNotFound(r, message);
                break;
              case 422:
                if (Api.responseEvents.onValidationError)
                  Api.responseEvents.onValidationError(
                    r,
                    message,
                    r.data.errors
                  );
                break;
              case 500:
                if (Api.responseEvents.onInternalError)
                  Api.responseEvents.onInternalError(r, message);
                break;
            }
          }
        }

        return Promise.reject(error).then((ee) => console.log(ee));
      }
    );
  }

  static listenResponseEvent(ev, cb) {
    if (!Api.responseEvents[ev]) {
      Api.responseEvents[ev] = cb;
    } else {
      console.log('Cannot add more then 1 event handler for "' + ev + '"');
    }
  }

  static listenRequestEvent(ev, cb) {
    if (!Api.requestEvents[ev]) {
      Api.requestEvents[ev] = cb;
    } else {
      console.log('Cannot add more then 1 event handler for "' + ev + '"');
    }
  }

  static toFormData(jsObject){
    const formData = new FormData();
    for ( var key in jsObject ) {
      formData.append(key, jsObject[key]);
    }

    return formData;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  static Login(login, password) {
    return Api.getInstance().post(Api.authURL + "oauth/token", {
      client_id: Api.client,
      client_secret: Api.secret,
      grant_type: "password",
      scopes: "",
      username: login,
      password: password,
    });
  }

  static Logout() {
    return Api.getInstance().post("v1/logout");
  }

  static downloadPdf = (response, filename) => {
    return new Promise((resolve, reject) => {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename + ".pdf";
      link.click();

      resolve();
    });
  };

  static printPdf = (url) => {
    return new Promise((resolve, reject) => {
      Api.getInstance()
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          const type = response.headers["content-type"];
          const blob = window.URL.createObjectURL(new Blob([response.data], { type: type, encoding: "UTF-8" }));
          const iframe = document.createElement('iframe');

          iframe.className = 'delivery-printable'
          document.body.appendChild(iframe);
          iframe.style.display = 'none';
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
              URL.revokeObjectURL(blob)
              // document.body.removeChild(iframe)
            }, 1);


            resolve();
          };
          iframe.src = blob;

        });
    });
  };

  static getPDF = (url, filename) => {
    return new Promise((resolve, reject) => {
      Api.getInstance()
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          await Api.downloadPdf(response, filename);
          resolve();
        });
    });
  };

  static Users = {
    Me: () => {
      return Api.getInstance().get("v1/users/me");
    },

    Get: (userId, query) => {
      return Api.getInstance().get("v1/users/" + userId + "?" + qr.stringify(query));
    },

    ACL: () => {
      return Api.getInstance().get("v1/users/acl");
    },

    Create: (data) => {
      return Api.getInstance().post(`v1/users`, data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/users/" + id, data);
    },

    List: (query = {}) => {
      return Api.getInstance().get("v1/users?" + qr.stringify(query));
    },

    ResetAccess: (userId, data) => {
      return Api.getInstance().post(`v1/users/${userId}/resetAccess`, data);
    },

    BlockAccess: (userId, data) => {
      return Api.getInstance().post(`v1/users/${userId}/restrictAccess`, data);
    },

  };

  static Brands = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/brands?" + qr.stringify(query));
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/brands?export=pdf", "Marcas-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/brands", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/brands/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/brands/" + id, data);
    },
  };

  static Categories = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/categories?" + qr.stringify(query));
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/categories?export=pdf", "Categorias-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/categories", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/categories/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/categories/" + id, data);
    },
  };

  static Cities = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/cities?" + qr.stringify(query));
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/cities?export=pdf", "Cidades-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/cities", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/cities/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/cities/" + id, data);
    },
  };

  static Divisions = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/divisions?" + qr.stringify(query));
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF(
        "v1/divisions?export=pdf",
        "Divisoes_Empresa-Marketplace"
      );
    },

    Create: (data) => {
      return Api.getInstance().post("v1/divisions", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/divisions/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/divisions/" + id, data);
    },
  };

  static Grids = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/grids?" + qr.stringify(query));
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/grids?export=pdf", "Grades-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/grids", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/grids/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/grids/" + id, data);
    },

    DeleteItem: (gridId, itemId) => {
      return Api.getInstance().delete(
        "v1/grids/" + gridId + "/items/" + itemId
      );
    },

    AddItem: (gridId, data) => {
      return Api.getInstance().post("v1/grids/" + gridId + "/items", data);
    },
  };

  static Menus = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/menus?" + qr.stringify(query));
    },

    Get: (id, query = {}) => {
      return Api.getInstance().get(
        "v1/menus/" + id + "?" + qr.stringify(query)
      );
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/menus?export=pdf", "Menus_Empresa-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/menus", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/menus/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/menus/" + id, data);
    },

    Items: (menuId) => {
      return Api.getInstance().get(
        "v1/menus/" +
        menuId +
        "/items?include=product,product.division,product.category"
      );
    },

    AddProduct: (menuId, data) => {
      return Api.getInstance().post("v1/menus/" + menuId + "/items", data);
    },
  };

  static Products = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/products?" + qr.stringify(query));
    },

    Get: (id, query) => {
      return Api.getInstance().get(
        "v1/products/" + id + "?" + qr.stringify(query)
      );
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF(
        "v1/products?export=pdf",
        "Produtos_Empresa-Marketplace"
      );
    },

    Create: (data, query) => {
      return Api.getInstance().post("v1/products" + "?" + qr.stringify(query), data);
    },

    Update: (id, data, query) => {
      return Api.getInstance().post("v1/products/" + id + "?" + qr.stringify(query), data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/products/" + id, data);
    },

    UploadImage: (id, image) => {
      const data = new FormData();
      data.append("image", image);

      return Api.getInstance().post("v1/products/" + id + "/images", data);
    },

    MakeImageDefault: (productId, imageId) => {
      return Api.getInstance().post(
        "v1/products/" + productId + "/images/" + imageId + "/default"
      );
    },

    DeleteImage: (productId, imageId) => {
      return Api.getInstance().delete(
        "v1/products/" + productId + "/images/" + imageId
      );
    },

    DeleteGridItem: (productId, itemId, query) => {
      return Api.getInstance().delete(
        "v1/products/" + productId + "/grid-items/" + itemId + "?" + qr.stringify(query)
      );
    },

    AddGridItem: (productId, data, query) => {
      return Api.getInstance().post(
        "v1/products/" + productId + "/grid-items" + "?" + qr.stringify(query),
        data
      );
    },

    UpdateGridItemStock: (productId, gridItemId, data) => {
      return Api.getInstance().post(
        "v1/products/" + productId + "/stock/" + gridItemId,
        data
      );
    },
  };

  static Companies = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/companies?" + qr.stringify(query));
    },

    Get: (id, query) => {
      return Api.getInstance().get("v1/companies/" + id + "?" + qr.stringify(query));
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/companies?export=pdf", "Empresas-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/companies", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/companies/" + id, data);
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/companies/" + id, data);
    },

    OpenClose: (isOpen) => {
      return Api.getInstance().post("v1/companies/working", {
        opened: isOpen ? 'true' : 'false',
      });
    },

    uploadImage: (id, image) => {
      const data = new FormData();
      data.append("image", image);

      return Api.getInstance().post("v1/companies/" + id + "/images", data);
    },
  };

  static Templates = {
    List: () => {
      return Api.getInstance().get(`v1/templates?withTemplateBody=true`);
    },

    Create: (data) => {
      return Api.getInstance().post(`v1/templates`, data);
    },

    Update: (id, data) => {
      return Api.getInstance().post(`v1/templates/${id}`, data);
    },

    Destroy: (id) => {
      return Api.getInstance().delete(`v1/templates/${id}`);
    },
  };

  static Orders = {
    List: (query = {}) => {
      return Api.getInstance().get("v1/orders?" + qr.stringify(query));
    },

    Get: (id, query = {}) => {
      return Api.getInstance().get(
        "v1/orders/" + id + "?" + qr.stringify(query)
      );
    },

    ExportPDF: (query = {}) => {
      return Api.getPDF("v1/orders?export=pdf", "Pedidos-Marketplace");
    },

    Create: (data) => {
      return Api.getInstance().post("v1/orders", data);
    },

    Update: (id, data) => {
      return Api.getInstance().post("v1/orders/" + id, data);
    },

    UpdateStatus: (id, status) => {
      return Api.getInstance().post("v1/orders/" + id + "/change-status", {
        status: status,
      });
    },

    Destroy: (id, data) => {
      return Api.getInstance().delete("v1/orders/" + id, data);
    },

    Printable_DeliveryReceipt: (id, query = {}) => {
      return Api.printPdf("v1/orders/" + id + "/print-delivery", "Pedido_" + id + "-Delivery.pdf");
    },

    Printable_ProductionReceipt: (id, query = {}) => {
      return Api.printPdf("v1/orders/" + id + "/print-production", "Pedido_" + id + "-Producao.pdf");
    },
  };
}
