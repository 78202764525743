import React from "react";
import Application from "~/@newestapps/App";

export default function App() {
    return (
        <React.Fragment>
            <Application/>
        </React.Fragment>
    );
}
