import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { Navbar, Alignment, NavbarGroup } from '@blueprintjs/core';

import appConfig from '~/Config/App';

export default function Footer(props) {
    const sidebarCollapsed = useSelector(state => state.preferences.sidebarCollapsed);
    const sidebarCollapsedRequested = useSelector(state => state.ui.requestSidebarCollapsed);

    return (
        <Navbar style={{
            position: 'fixed',
            bottom: 0,
            height: 45,
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: 12,
            display: 'flex',
            left: (sidebarCollapsedRequested === true || sidebarCollapsed) ? 49 : 230,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>

            <NavbarGroup align={Alignment.LEFT}>
                <b><a href={appConfig.company.website} target={'__blank'} style={{ paddingRight: 5, }}>{appConfig.company.name}</a></b>
                {appConfig && appConfig.company && appConfig.company.whatsapp ? (
                    <React.Fragment>
                        - WhatsApp:{'  '}<a
                            href={"https://api.whatsapp.com/send?phone=+55" + appConfig.company.whatsapp.replace(' ', '').replace('-', '')} target={'__blank'}
                            style={{ paddingLeft: 5, }}>{'  '}{appConfig.company.whatsapp}</a>
                    </React.Fragment>
                ) : null}
            </NavbarGroup>

            {/*<NavbarGroup align={Alignment.RIGHT}>*/}
            {/*    <a href="http://newestapps.com.br/" target={'__blank'} title={'Desenvolvido por Newestapps Brasil'}>*/}
            {/*        <img src={'/Newestapps_white.svg'} style={{ height: 25, }} />*/}
            {/*    </a>*/}
            {/*</NavbarGroup>*/}

        </Navbar>
    );

}

