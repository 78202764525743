export default class FormattingHelper {
  static moneyMask(value, decimalPlaces = 2) {
    let decimal = value / 100;
    let str = decimal.toFixed(2).toString().replace(".", ",");
    let fmt1 = FormattingHelper.numberWithCommas(str);
    return "R$ " + fmt1;
  }

  static numberFormat(value, decimalPlaces = 2) {
    let fmt1 = FormattingHelper.numberWithCommas(value);
    return fmt1;
  }

  static unmaskMoney(value) {
    let integerValue = value.toString().replace("R$", "");
    integerValue = integerValue.toString().replace(".", "");
    integerValue = integerValue.toString().replace(",", "");
    return integerValue;
  }

  static numberWithCommas(x) {
    var parts = String(x).split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  };

  static bytesToSize(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  static getInitialsFromName(name) {
    var names = name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  static plural(count, singular, plural) {
    if (count === 1) {
      return singular;
    }

    return plural;
  }

  static taxDocumentName(tp) {
    switch (tp) {
      case "CPF":
        return "CPF";
      case "RG":
        return "RG";
      case "CNH":
        return "CNH";
      case "CEDULA_PY":
        return "Cédula de Identidad";
      case "PASSPORT":
        return "Passaporte";
    }
  }

  static labelTaxDocumentType(tp) {
    switch (tp) {
      case "CPF":
        return "Nº CPF";
      case "RG":
        return "Nº RG";
      case "CNH":
        return "Nº Registro CNH";
      case "CEDULA_PY":
        return "Nº Cédula PY";
      case "PASSPORT":
        return "Cod. Passaporte";
    }
  }

  static maskTaxDocumentType(tp) {
    switch (tp) {
      case "CPF":
        return "[000]{.}[000]{.}[000]{-}[00]";
      case "RG":
        return "[00]{.}[000]{.}[000]{-}[0]";
      case "CNH":
        return "[00000000000]";
      case "CEDULA_PY":
        return "[0]{.}[000]{.}[000]";
      case "PASSPORT":
        return "[A…]";
    }
  }

  static formatTaxDocument(value, type = "CNPJ") {
    function mascaraCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    }

    function mascaraCnpj(valor) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }

    if (type === "CNPJ") {
      return mascaraCnpj(value);
    } else {
      return mascaraCpf(value);
    }
  }

  static parseParams(str) {
    str = str.substr(1);
    var pieces = str.split("&"),
      data = {},
      i,
      parts;
    // process each query pair
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split("=");
      if (parts.length < 2) {
        parts.push("");
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }

    return data;
  }

  static phoneNumberFormat(ddd, phone) {
    const isCellphone = phone.length === 9;
    let startPhone = "";
    let endPhone = "";

    if (isCellphone) {
      startPhone = phone.slice(0, 5);
      endPhone = phone.slice(5, 9);
    } else {
      startPhone = phone.slice(0, 4);
      endPhone = phone.slice(4, 8);
    }

    if (ddd.length > 0) {
      return `(${ddd}) ${startPhone}-${endPhone}`;
    }

    return `${startPhone}-${endPhone}`;
  }
}
