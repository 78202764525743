import React from "react";
import styled from "styled-components";
import NewestappsTheme from '~/@newestapps/Theme';

import {
  FormControl as FormControlMD,
  Button as ButtonMD,
  Switch as SwitchMD,
  Checkbox as CheckboxMD,
  MenuItem as MenuItemMD,
  FormControlLabel as FormControlLabelMD,

  AppBar as AppBarMD
} from "@material-ui/core";

import { TextField } from "unform-material-ui";

export const PageContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${props =>
    props.centerHorizontal
      ? `
  align-items: center;
  `
      : ""}
`;

export const Section = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Screen = styled.main`
 padding: 40px;
`;

export const H1 = styled.h1`
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary};
  ${props => (props.pb ? "padding-bottom:" + props.pb + "px" : "")}
`;

export const H2 = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.colors.secondary};
  ${props => (props.pb ? "padding-bottom:" + props.pb + "px" : "")}
`;

export const H3 = styled(H2)`
  font-size: 15px;
  color: ${props => props.theme.colors.primary};
`;

export const H4 = styled(H3)`
  color: ${props => props.theme.colors.secondary};
`;

export const H5 = styled(H3)`
  color: ${props => props.theme.colors.sectionHeader};
`;

export const Hint = styled.p`
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.colors.hint};
  -webkit-font-smoothing: antialiased;
  ${props => (props.pb ? "padding-bottom:" + props.pb + "px" : "")}
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.theme.colors.text};
`;

export const HR = styled.hr`
  border: 0;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
`;

export const Surface = styled.div`
  background-color: ${props => props.theme.colors.surface};
`;

export const FormControl = styled(FormControlMD).attrs(attrs => ({
  fullWidth: true
}))``;

export const FormControlLabel = styled(FormControlLabelMD).attrs(attrs => ({
  fullWidth: true
}))``;

export const Input = styled(TextField).attrs(attrs => ({
  variant: "outlined"
}))``;

export const Switch = styled(SwitchMD).attrs(attrs => ({
  color: "primary"
}))``;

export const Checkbox = styled(CheckboxMD).attrs(attrs => ({
  color: "primary"
}))``;

export const Button = styled(ButtonMD).attrs(attrs => ({
  color: attrs.color ? attrs.color : "primary",
  variant: attrs.variant ? attrs.variant : "contained"
}))`
`;

export const MenuItem = styled(MenuItemMD).attrs(attrs => ({}))``;

export const AppBar = styled(AppBarMD).attrs(attrs => ({
  component: 'div',
  color: 'secondary',
  position: 'static',
  elevation: 0,
}))``;
