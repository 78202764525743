import React from 'react';
import styled from 'styled-components';

export const Logo = styled.img.attrs(attrs => ({
    src: attrs.login ? attrs.theme.logoLogin : attrs.theme.logo,
}))` 
    ${props => props.login ? `
        height: 100px;
    ` : `
        margin-top: 5px;
        margin-left: -12px;
    `}
`;

export const Content = styled.div`
    margin:0 15px;
`;
