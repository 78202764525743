import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import * as Yup from "yup";

// Components
import { Text, Button, PrimaryButton, Stack } from '@fluentui/react';
import { Logo } from "~/Components";
import { Grid } from '@material-ui/core';

import {
  Container,
  LoginContainer,
  FormContainer,
} from './styles';

import { Form } from "@unform/web";
import { Label, Input } from '~/@newestapps/Form';

import { AcheiCorridas } from 'acheicorridas';
import configAC from '~/Config/AcheiCorridas';

const acheiCorridas = new AcheiCorridas(configAC);

export default function LoginScreen() {
  const formRef = React.useRef();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  return (
    <Container>

      <LoginContainer>

        <Stack
          tokens={{
            childrenGap: 30,
          }}
        >

          <Stack.Item align="center">
            <Logo login />
          </Stack.Item>

          <Stack.Item align="center">
            <Text variant={'xLarge'}>
              Acesso do Organizador
            </Text>
          </Stack.Item>

          <Stack.Item align="center">
            <PrimaryButton text={'Entrar com AcheiCorridas'} iconProps={{ iconName: 'Signin' }} disabled={loading} onClick={() => { 
              window.location.href = acheiCorridas.getAuthorizationUrl();
            }} type="submit" style={{ width: 250, }} />
          </Stack.Item>

        </Stack>

      </LoginContainer>

    </Container>
  );
}


// <Container>
//       <Header>
//         <PageContent>
//           <Logo />
//         </PageContent>
//       </Header>

//       <PageContent centerHorizontal>
//         <LoginBox>
//           <H1>Entrar</H1>
//           <Hint pb={20}>
//             Informe seu login e senha de acesso que enviamos pra você
//           </Hint>

          // <FormControl>
          //   <Input
          //     name={"username"}
          //     label="CPF/CNPJ"
          //     value={login}
          //     maxLength={20}
          //     error={!isEmpty(error)}
          //     disabled={loading}
          //     onChange={e => {
          //       setLogin(e.target.value);
          //     }}
          //   />
          // </FormControl>

//           <FormControl style={{ marginTop: 15 }}>
//             <Input
//               name={"password"}
//               type="password"
//               label="SENHA DE ACESSO"
//               value={password}
//               maxLength={30}
//               error={!isEmpty(error)}
//               disabled={loading}
//               onChange={e => {
//                 setPassword(e.target.value);
//               }}
//             />
//           </FormControl>

//           <FormControl style={{ marginTop: 15 }}>
//             <Button onClick={signin} disabled={loading} loading={loading}>
//               Entrar
//             </Button>
//           </FormControl>
//         </LoginBox>
//       </PageContent>
//     </Container>
