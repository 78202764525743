import React, {useEffect, useRef, useState} from "react";
import {useField} from "@unform/core";
import {DateInput, TimePrecision} from "@blueprintjs/datetime";
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/pt-br';

import momentT from 'moment';
import moment from 'moment-timezone';

export const LOCALE = 'pt-br';
export const SHORTCUTS = [
    {label: 'Hoje', date: momentT().toDate()},
    {label: 'Ontém', date: momentT().subtract(1, 'days').toDate()},
    {label: 'Amanhã', date: momentT().add(1, 'days').toDate()},
    {label: 'Semana Passada', date: momentT().subtract(1, 'weeks').toDate()},
    {label: 'Semana que vem', date: momentT().add(1, 'weeks').toDate()},
    {label: 'Mês Passado', date: momentT().subtract(1, 'months').toDate()},
    {label: 'Mês que vem', date: momentT().add(1, 'months').toDate()},
    {label: 'Ano passado', date: momentT().subtract(1, 'years').toDate()},
    {label: 'Ano que vem', date: momentT().add(1, 'years').toDate()},
];

export function getMomentFormatter(format) {
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        placeholder: format,
    }
}

export default function Component({name, time = false, seconds = false, friendly = false, birthday = false, ...rest}) {
    const inputRef = useRef();
    const {fieldName, registerField, defaultValue} = useField(name);
    const [value, setValue] = useState(defaultValue ? moment(defaultValue).toDate() : null);

    const useFormat = !time ? (!friendly ? 'DD/MM/YYYY' : 'dddd, D [de] MMMM [de] YYYY') : (!seconds ? (!friendly ? 'DD/MM/YYYY HH:mm' : 'dddd, D [de] MMMM [de] YYYY [às] HH:mm') : (!friendly ? 'DD/MM/YYYY HH:mm:ss' : 'dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss'));
    const useFormatValue = !time ? ('YYYY-MM-DD') : (!seconds ? ('YYYY-MM-DD HH:mm:00') : ('YYYY-MM-DD HH:mm:ss'));

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: "props.value",
            getValue: ref => {
                return moment(ref.current.props.value).format(useFormatValue);
            },
            setValue: val => {
                setValue(moment(val).toDate());
            }
        });
    }, [fieldName, registerField]);

    const timePrecision = !birthday && time ? {
        timePrecision: !birthday && time ? (seconds ? TimePrecision.SECOND : TimePrecision.MINUTE) : false,
        timePickerProps: {
            selectAllOnFocus: true,
            ...rest.timePickerProps,
        }
    } : {};

    return (
        <DateInput
            ref={inputRef}
            locale={LOCALE}
            localeUtils={MomentLocaleUtils}
            placeholder={''}
            value={value}
            clearButtonText={'Limpar'}
            todayButtonText={'Hoje'}
            shortcuts={!birthday ? SHORTCUTS : false}
            minDate={birthday ? (new Date(1900, 1, 1, 0, 0, 0)) : null}
            closeOnSelection={!time}
            onChange={(val) => {
                setValue(val);
            }}
            canClearSelection
            showActionsBar
            fill
            {...getMomentFormatter(useFormat)}
            {...timePrecision}
            {...rest}
        />
    );
}
