import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { FileInput } from "@blueprintjs/core";

export default function Component({ name, ...rest }) {
  const inputRef = useRef();
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        const ip = ref.current;

        return ip.files;
      }
    });
  }, [fieldName, registerField]);

  return (
    <FileInput
      intent={error ? 'danger' : 'none'}
      name={name}
      inputProps={{
        ref: ref => {
          inputRef.current = ref;
        }
      }}
      {...rest}
    />
  );
}
