import React from "react";

// Layout
import Screen from '~/@newestapps/Layout/Screen';
import Content from '~/@newestapps/Layout/Content';

import BreadCrumb from '~/Components/BreadCrumb';
import {NonIdealState} from "@blueprintjs/core";

export default function HomeScreen() {

  return (
    <React.Fragment>
      <BreadCrumb items={[
        { icon: 'home', text: 'Início' }
      ]} />

      <Screen>
        <Content style={{padding: 150}}>

            <NonIdealState
                title={'404!'}
                icon={'not-equal-to'}
                description={'Desculpe, o recurso que você esta procurando não existe!'}
            />

        </Content>
      </Screen>

    </React.Fragment>
  );
}

