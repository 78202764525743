import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';
import { InputGroup } from '@blueprintjs/core';
import { useSnackbar } from "notistack";

function MoneyInput({ name, asString, label, ...rest }) {
  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [value] = useState(defaultValue);

  const NumberFormatInput = () => (
    <NumberFormat
      ref={inputRef}
      defaultValue={value/100}
      helperText={!error ? rest.helperText : error}
      error={!isEmpty(error)}
      allowNegative={false}
      allowEmptyFormatting={false}
      allowLeadingZeros={false}
      decimalScale={2}
      fixedDecimalScale={2}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      intent={error ? 'danger': 'none'}
      customInput={InputGroup}
      label={label}
      variant="outlined"
      prefix="R$ "
      {...rest}
    />
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        return parseInt(ref.current.state.numAsString.replace('.', ''));
      }
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if(error){
      enqueueSnackbar(error, {
        variant: 'warning',
      })
    }
  }, [error]);

  return (
    <NumberFormatInput />
  );
}

export default MoneyInput;
