import React, {useEffect, useRef, useState} from 'react';
import {Button, Popover, Position} from "@blueprintjs/core";
import {Actions} from "~/@newestapps/Layout";

import {Container, Footer} from './styles';
import {Form} from "@unform/web";

let cachedFilters = {};

export default function Content({children, onFilter, label = 'Filtros'}) {
    const formRef = useRef(null);
    const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = async (data) => {
        console.log(data);

        if (onFilter)
            onFilter(data);

        cachedFilters = data;
    };

    const clearFilters = () => {
        if(onFilter){
            onFilter({});
        }

        cachedFilters = {};
        setIsOpen(false);
    };

    // useEffect(() => {
    //     if(formRef && formRef.current && cachedFilters){
    //         formRef.current.setData(cachedFilters);
    //         console.log('cachedFilters', cachedFilters);
    //     }
    // }, [isOpen, cachedFilters, hasFiltersApplied]);

    return (
        <Popover
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            content={(
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Container>
                        {children}
                    </Container>

                    <Footer>
                        <Button icon={'cross'} intent={'danger'} outlined style={{marginRight: 15,}} onClick={clearFilters}>
                            Limpar
                        </Button>
                        <Button icon={'filter'} outlined onClick={() => formRef.current.submitForm()}>
                            Filtrar
                        </Button>
                    </Footer>
                </Form>
            )} position={Position.BOTTOM_LEFT}>
            <Actions.Button
                icon={"filter-list"}
                intent={hasFiltersApplied ? 'danger' : 'none'}
                onClick={() => setIsOpen(!isOpen)}
            >
                {label}
            </Actions.Button>
        </Popover>
    );

}
