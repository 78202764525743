import React from "react";
import { FormGroup } from "@blueprintjs/core";

export default function Component({ children, label, ...rest }) {
  return (
    <FormGroup
      label={label}
      fill
      {...rest}
    >
      {children}
    </FormGroup>
  );
}
