import React from 'react';
import styled from 'styled-components';
import {Icon} from "@blueprintjs/core";

export const Container = styled.div`
    width: ${props => props.collapsed ? 49 : 230}px;
    position: fixed;
    left:0;
    top: 51px;
    bottom: 0;
    background: white;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
    z-index: 19;
    display: flex;
    flex-direction: column;
`;

export const MenuCollapse = styled.div`
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
    background: rgb(252,253,254);
    background: linear-gradient(180deg, rgba(252,253,254,1) 0%, rgba(245,247,249,1) 100%);
    left: ${props => props.collapsed ? 49 : 230}px;
    position: fixed;
    width: 6px;
    top: 51px;
    bottom: 0;
    z-index: 19;
    display: flex;
    cursor: pointer;
    
    &:hover {
        background: rgb(244,245,246);
        background: linear-gradient(180deg, rgb(241,242,243) 0%, rgb(228,230,232) 100%);
    }
`;

export const CollapsedMenu = styled.div`
  width: 50px;
  padding: 5px;
`;
