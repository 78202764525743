import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import CodeMirror from 'react-codemirror';

let mValue;

export default function Component({ name, options = {}, children, value, ...rest }) {
  const inputRef = useRef();
  const { fieldName, registerField, defaultValue } = useField(name);

  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    mValue = defaultValue;

    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        return mValue;
      },
      setValue: (ref, valueToSet) => {
        // console.log(ref, valueToSet);
      }
    });
  }, [fieldName, registerField]);

  const handleChange = val => {
    setVal(val);
    mValue = val;
  };

  return (
    <CodeMirror
      {...rest}
      value={val}
      onChange={handleChange}
      options={{
        lineNumbers: true,
        ...options,
      }}
    />
  );
}
