import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { TagInput } from "@blueprintjs/core";

let mValues = [];

export default function Component({ name, asString = false, joint = ',', ...rest }) {
  const inputRef = useRef();
  const { fieldName, registerField, defaultValue } = useField(name);

  const values = defaultValue ? (Array.isArray(defaultValue) ? defaultValue : defaultValue.split(',')) : [];
  const [inValues, setInValues] = useState(values);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        if (asString) {
          return mValues.join(joint);
        }
        
        return mValues;
      }
    });
  }, [fieldName, registerField]);

  return (
    <TagInput
      addOnBlur
      inputRef={ref => {
        inputRef.current = ref;
      }}
      onChange={(values) => {
        setInValues(values);
        mValues = values;
      }}
      values={inValues}



      {...rest}
    />
  );
}
