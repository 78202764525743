import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import {Button, MenuItem, Spinner, Icon, ControlGroup} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import { debounce, snakeCase, isEmpty, trim } from 'lodash';

export default function Component({ name, options = [], selected, primaryKey = 'id', ...rest }) {

  const inputRef = useRef();
  const searchInputRef = useRef();

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [dataset, setDataSet] = useState(options);
  const [selectedItem, setSelectedItem] = useState(!selected ? (options && options.length > 0 ? options[0] : {}) : selected);
  const [searchQuery, setSearchQuery] = useState('');

  // useEffect(() => {
  //   setDataSet(options);
  // }, [options]);

  useEffect(() => {
      if (defaultValue && options) {
        for (let i = 0; i < options.length; i++) {
          if (String(defaultValue) === String(options[i][primaryKey])) {
            setSelectedItem(options[i]);
            break;
          }
        }
      }

  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "value",
      getValue: ref => {
        return ref.current.value;
      }
    });
  }, [fieldName, registerField]);

  const handleQueryChange = (query) => {
    performSearch(query);
  };

  const performSearch = useCallback(debounce((query) => {
    if (isEmpty(trim(query))) {
      setDataSet(options);
      return;
    }

    setSearchQuery(query);

    if (searchInputRef && searchInputRef.current) searchInputRef.current.focus();

    query = snakeCase(query);

    if (options) {
      const results = options.filter(i => {
          const field = i.label;
          if (snakeCase(field).indexOf(query) >= 0) {
            return i;
          }
      });

      setDataSet(results);
    }
  }, 50));

  return (
      <ControlGroup fill>
        <Select
            items={dataset}
            popoverProps={{
              modifiers: {
                arrow: false,
                preventOverflow: 'window',
                usePortal: true,
                position: 'bottom',
              },
              preventOverflow: 'window',
              usePortal: true,
              position: 'bottom',
            }}
            filterable={true}
            inputProps={{
              placeholder: 'Pesquisar...',
              inputRef: searchInputRef,
              autoFocus: true,
            }}
            resetOnClose
            noResults={<MenuItem disabled={true} text="Nenhum resultado." />}
            onQueryChange={handleQueryChange}
            scrollToActiveItem
            itemRenderer={(item) => {
              return (
                  <MenuItem
                      onClick={() => {
                        setSelectedItem(item);
                        setTimeout(() => {
                          if (inputRef && inputRef.current)
                            inputRef.current.focus();
                        }, 100);
                      }}
                      active={selectedItem.value === item.value}
                      text={item.label}
                      {...rest.selectItemProps}
                  />
              );
            }}
            {...rest.selectProps}
        >
          <Button
              disabled={rest.loading}
              fill
              rightIcon={'chevron-down'}
          >
            {selectedItem.label}
          </Button>
        </Select>
      </ControlGroup>
  );
}
