import React, {useEffect, useRef, useState} from "react";
import {useField} from "@unform/core";
import {Slider} from "@blueprintjs/core";

export default function Component({name, min = 0, max = 100, stepSize = 1.0, ...rest}) {
    const inputRef = useRef();
    const {fieldName, registerField, defaultValue} = useField(name);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: "props.value",
            getValue: ref => {
                return ref.current.props.value;
            },
            setValue: val => {
                setValue(val);
            }
        });
    }, [fieldName, registerField]);

    return (
        // <Checkbox
        //     name={name}
        //     inputRef={ref => {
        //       inputRef.current = ref;
        //     }}
        //     defaultChecked={defaultValue}
        //     {...rest}
        // />

        <Slider
            min={min}
            max={max}
            stepSize={stepSize}
            labelStepSize={10}
            onChange={(val) => {
                setValue(val);
            }}
            value={value}
            ref={inputRef}
            {...rest}
        />
    );
}
