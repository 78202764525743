import React, {useEffect, useRef, useState} from "react";
import {useField} from "@unform/core";
import {DateRangeInput, TimePrecision} from "@blueprintjs/datetime";
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/pt-br';

import momentT from 'moment';
import moment from 'moment-timezone';

import {LOCALE, getMomentFormatter} from "~/@newestapps/Form/DateInput";

export const SHORTCUTS = [
    {label: 'Hoje', dateRange: [momentT().toDate(), momentT().toDate()]},
    {label: 'Última Semana', dateRange: [momentT().subtract(1, 'weeks').toDate(), momentT().toDate()]},
    {label: 'Próxima Semana', dateRange: [momentT().toDate(), momentT().add(1, 'weeks').toDate()]},
    {label: 'Último Mês', dateRange: [momentT().subtract(1, 'months').toDate(), momentT().toDate()]},
    {label: 'Próximo Mês', dateRange: [momentT().toDate(), momentT().add(1, 'months').toDate()]},
    {label: 'Último Ano', dateRange: [momentT().subtract(1, 'years').toDate(), momentT().toDate()]},
    {label: 'Próximo Ano', dateRange: [momentT().toDate(), momentT().add(1, 'years').toDate()]},
];

export default function Component({name, time = false, seconds = false, friendly = false, ...rest}) {
    const inputRef = useRef();
    const {fieldName, registerField, defaultValue} = useField(name);
    const [value, setValue] = useState(!Array.isArray(defaultValue) ? [] : defaultValue);

    const useFormat = !time ? (!friendly ? 'DD/MM/YYYY' : 'dddd, D [de] MMMM [de] YYYY') : (!seconds ? (!friendly ? 'DD/MM/YYYY HH:mm' : 'dddd, D [de] MMMM [de] YYYY [às] HH:mm') : (!friendly ? 'DD/MM/YYYY HH:mm:ss' : 'dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss'));
    const useFormatValue = !time ? ('YYYY-MM-DD') : (!seconds ? ('YYYY-MM-DD HH:mm:00') : ('YYYY-MM-DD HH:mm:ss'));

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: "props.value",
            getValue: ref => {
                const out = [];

                ref.current.props.value.forEach(o => {
                    const momo = moment(o);
                    if (momo.isValid())
                        out.push(momo.format(useFormatValue));
                });

                return out;
            },
            setValue: val => {
                setValue(val);
            }
        });
    }, [fieldName, registerField]);

    return (
        <DateRangeInput
            fill
            {...getMomentFormatter(useFormat)}
            locale={LOCALE}
            localeUtils={MomentLocaleUtils}
            ref={inputRef}
            placeholder={''}
            onChange={(val) => {
                setValue(val);
            }}
            value={value}
            clearButtonText={'Limpar'}
            todayButtonText={'Hoje'}
            canClearSelection
            showActionsBar
            shortcuts={SHORTCUTS}
            timePrecision={time ? (seconds ? TimePrecision.SECOND : TimePrecision.MINUTE) : null}
            timePickerProps={time ? {
                selectAllOnFocus: true,
                ...rest.timePickerProps,
            } : null}
            allowSingleDayRange
            closeOnSelection={!time}
            startInputProps={{
                placeholder: 'Início',
            }}
            endInputProps={{
                placeholder: 'Fim'
            }}
            {...rest}
        />
    );
}
