export function showLoadingOverlay(text = null, props = {}) {
  return {
    type: 'SHOW_LOADING_OVERLAY',
    visible: true,
    text,
    ...props,
  };
}

export function hideLoadingOverlay() {
  return {
    type: 'HIDE_LOADING_OVERLAY',
    visible: false,
  };
}

export function showDialog(dialog, params = {}) {
  return {
    type: 'SHOW_DIALOG',
    dialog,
    params,
  };
}

export function openDialog(dialog, params = {}) {
  return showDialog(dialog, params);
}

export function closeDialog(dialog) {
  return {
    type: 'CLOSE_DIALOG',
    dialog,
  };
}

export function finishBoot() {
  return {
    type: 'BOOT',
  };
}

export function setACL(ACL) {
  return {
    type: 'ACLS',
    acls: ACL,
  };
}

export function setUser(user) {
  return {
    type: 'SET_USER',
    user: user,
  };
}

export function setBreadcrumb(breadcrumb = [], extend = false) {
  return {
    type: 'SET_BREADCRUMB',
    breadcrumb, extend
  };
}

export function resetBreadcrumb(name, path) {
  return {
    type: 'RESET_BREADCRUMB',
    name, path,
  };
}

export function clearBreadcrumb() {
  return {
    type: 'CLEAR_BREADCRUMB',
  };
}

export function setWebsocketConnected(e) {
  return {
    type: 'SET_WEBSOCKET',
    echo: e,
  };
}

export function openImageEditor(image) {
  return {
    type: 'OPEN_GLOBAL_IMAGE_EDITOR',
    image,
  };
}

export function requestSidebarCollapsed(collapsed = true) {
  return {
    type: 'SET_SIDEBAR_COLLAPSED',
    collapsed,
  };
}
