import React, { Suspense } from "react";
import { Routes as Router, Route } from "react-router-dom";
import { Spinner } from "@blueprintjs/core";
import { collect } from "collect.js";

import NotFoundScreen from '~/@newestapps/Screens/NotFoundScreen';

export default function Routes() {
    const definedRoutes = collect(require('~/Config/Routes.json'))
        .whereNotIn('type', ['header'])
        .where('screen', '!==', null);

    return (
        <Suspense fallback={() => {
            return (
                <Spinner size={200} >
                </Spinner>
            );
        }}>
            <Router>

                {definedRoutes.map(route => {
                    const _path = route.path;
                    const childRoutes = route.childRoutes ? collect(route.childRoutes) : collect();
                    const rootScreen = childRoutes.count() > 0 ? route.screen + '/' : '';

                    const Screen = React.lazy(async () => {
                        const scr = await (new Promise((resolve, reject) => {
                            resolve(import('~/' + route.screen + '/index.js'));
                        }));

                        return scr;
                    });

                    return (
                        childRoutes && childRoutes.count() > 0 ? (
                            <Route path={_path} element={<Screen />} >

                                {childRoutes.map(childRoute => {
                                    const ChildScreen = React.lazy(async () => {
                                        const scr = await (new Promise((resolve, reject) => {
                                            resolve(import('~/' + rootScreen + childRoute.screen + '/index.js'));
                                        }));

                                        return scr;
                                    });

                                    return (
                                        <Route path={childRoute.path} element={<ChildScreen />} />
                                    )
                                })}

                            </Route>
                        ) : (
                            <Route path={_path} element={<Screen />} />
                        )
                    )
                })}

                <Route path={'*'} element={<NotFoundScreen />} />

            </Router>
        </Suspense>
    );
}


/**
 *  <Route path="/sistema/usuarios" element={<UsersScreen />}>
 <Route path="/" element={<ListUsersScreen />} />
 <Route path="/detalhes/:userId" element={<DetailsUserScreen />} />
 </Route>

 <Route path="/sistema/templates" element={<TemplatesScreen />}>
 <Route path="/" element={<ListTemplatesScreen />} />
 </Route>

 <Route path="/marketplace" element={<MarketplaceScreen />}>
 <Route path="/pedidos" element={<OrdersScreen />}>
 <Route path="/" element={<ListOrdersScreen />} />
 <Route path="/detalhes/:orderId" element={<DetailsOrdersScreen />} />
 </Route>

 <Route path="/marcas" element={<BrandsScreen />}>
 <Route path="/" element={<ListBrandsScreen />} />
 </Route>

 <Route path="/categorias" element={<CategoriesScreen />}>
 <Route path="/" element={<ListCategoriesScreen />} />
 </Route>

 <Route path="/cidades" element={<CitiesScreen />}>
 <Route path="/" element={<ListCitiesScreen />} />
 </Route>

 <Route path="/divisoes" element={<DivisionsScreen />}>
 <Route path="/" element={<ListDivisionsScreen />} />
 </Route>

 <Route path="/menus" element={<MenusScreen />}>
 <Route path="/" element={<ListMenusScreen />} />
 <Route path="/itens/:menuId" element={<ManageMenuItensScreen />} />
 </Route>

 <Route path="/grades" element={<GridsScreen />}>
 <Route path="/" element={<ListGridsScreen />} />
 </Route>

 <Route path="/produtos" element={<ProductsScreen />}>
 <Route path="/" element={<ListProductsScreen />} />
 <Route path="/adicionar" element={<CreateProductsScreen />} />
 <Route
 path="/alterar/:productId"
 element={<UpdateProductsScreen />}
 />
 </Route>

 <Route path="/empresas" element={<CompaniesScreen />}>
 <Route path="/" element={<ListCompaniesScreen />} />
 <Route path="/adicionar" element={<CreateCompaniesScreen />} />
 <Route
 path="/alterar/:companyId"
 element={<CreateCompaniesScreen />}
 />
 </Route>
 </Route>
 */
