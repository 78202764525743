import React, {useEffect, useRef, useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {useField} from "@unform/core";
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

// Components
import {Button, ButtonGroup, Card} from "@blueprintjs/core";
import {FormattingHelper} from "~/@newestapps/Helpers";

// Actions
import {openImageEditor} from "~/@newestapps/Redux/actions/ui";

const DropZoneContainer = styled.div`
  padding: 45px;
  margin-top: 4px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  ${props => props.isDragActive ? `
    color: #38b058;
    border: 2px dashed #38b058;
  ` : `
    color: #cccccc;
    border: 2px dashed #e1e1e1;
  `}
`;

const ImagePreviewContainer = styled(Card)`
  text-align: center;
  background-image: url('/Assets/UI/image_transparency_layer.png');
`;

const ImagePreview = styled.img`
  max-width: 150px;
  border: 1px solid #cccccc;
`;

const ImagePreviewDetails = styled(Card)`
  margin-top: 15px;
  padding: 6px;
`;

const ImagePreviewDetailsSize = styled.div`
  color: #666666;
  font-size: 12px;
`;

const ImagePreviewControls = styled(ButtonGroup)`
  margin-top: 6px;
`;

export default function Component({name, maxFiles = 1, basic = false, ...rest}) {
    const inputRef = useRef();
    const {fieldName, registerField} = useField(name);
    const [files, setFiles] = useState();

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: ['image/jpg', 'image/jpeg', 'image/png'],
        multiple: maxFiles > 1,
        ...rest,
    });

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: "value",
            getValue: ref => {
                if (files && maxFiles === 1 && files.length > 0) return files[0];

                return files;
            }
        });
    }, [fieldName, registerField, files]);

    return (
        <React.Fragment>
            <input type={'hidden'} ref={inputRef} name={name}/>

            {files && files.length > 0 && maxFiles === 1 ? (
                <React.Fragment>

                    <ImagePreviewContainer>
                        <ImagePreview src={URL.createObjectURL(files[0])} title={files[0].name}/>
                        {!basic ? (
                            <ImagePreviewDetails>
                                <div><b>{files[0].name}</b></div>
                                <ImagePreviewDetailsSize>{FormattingHelper.bytesToSize(files[0].size)}</ImagePreviewDetailsSize>
                            </ImagePreviewDetails>
                        ) : null}
                    </ImagePreviewContainer>

                    <ImagePreviewControls>
                        <Button onClick={() => setFiles(null)} icon={'cross'}>
                            Remover
                        </Button>
                        {/*<Button onClick={() => {*/}
                        {/*    dispatch(openImageEditor(files[0]));*/}
                        {/*}} icon={'eye-open'}/>*/}
                    </ImagePreviewControls>
                </React.Fragment>
            ) : (
                <DropZoneContainer {...getRootProps()} isDragActive={isDragActive}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>{maxFiles === 1 ? 'Solte a imagem aqui' : 'Solte as imagens aqui'}</p> :
                            <p>{maxFiles === 1 ? 'Arraste uma imagem aqui ou clique para selecionar' : 'Arraste suas imagens aqui ou clique para selecionar'}</p>
                    }
                </DropZoneContainer>
            )}

        </React.Fragment>
    );
}
