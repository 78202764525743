import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Switch } from "@blueprintjs/core";

export default function Component({ name, ...rest }) {
  const inputRef = useRef();
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: "checked",
      getValue: ref => {
        const ip = ref.current;
        return ip.checked;
      }
    });
  }, [fieldName, registerField]);

  return (
    <Switch
      name={name}
      inputRef={ref => {
        inputRef.current = ref;
      }}
      defaultChecked={defaultValue}
      {...rest}
    />
  );
}
