import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function GlobalEvents() {
    const Echo = useSelector(state => state.ui.echo);
    const user = useSelector(state => state.ui.user);

    useEffect(() => {
        if (Echo !== null) {

            Echo.private('App.User.' + user.id);
                // .listen('OrderCreatedEvent', (e) => {
                //
                // });
        }
    }, [Echo]);

    return (
        <React.Fragment>

        </React.Fragment>
    );
}
