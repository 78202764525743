import React from "react";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { normalize } from "styled-normalize";

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${normalize}

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.theme.colors.windowBackground};
    width: 100%;
    height: 100%;
    font-family:-apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif; 
  }

  * {
    outline: none !important;
  }

  ::selection {
    color: ${props => props.theme.colors.textContrast};
    background: ${props => props.theme.colors.primary};
  }

  ::-moz-selection {
      color: ${props => props.theme.colors.textContrast};
      background: ${props => props.theme.colors.primary};
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #24313c;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #303e49;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #24313c;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-track:active {
    background: #ffffff;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

`;

export default GlobalStyle;
