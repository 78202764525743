export function savePreference(key, value) {
  return {
    type: 'SAVE_PREFERENCE',
    key,
    value,
  };
}

export function logout() {
  return {
    type: 'LOGOUT',
  };
}
