import React from "react";
import styled from "styled-components";
import {random} from 'lodash';

import { Surface } from "~/Components/UI";

const bgTurn = random(1, 6);
const bgImage = bgTurn === 0 ? '/login/background.jpg' :  '/login/background_'+bgTurn+'.jpg' ;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.primary};
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background-image: url('${bgImage}');
  background-position: center;
  background-size: cover;
`;

export const LoginContainer = styled.div`
  position:fixed;
  right:0;
  top:0;
  bottom:0;
  min-width: 480px;
  max-width: 700px;
  width: 50%;
  background-color: white;
  box-shadow: 1px -1px 15px rgba(0,0,0,0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  width: 400px;
`;

export const Header = styled(Surface)`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const Logo = styled.img.attrs(attrs => ({
  src: attrs.theme.logoOriginal,
  alt: "Newestapps"
}))`
  width: 340px;
  height: 70px;
  margin: 0 auto;
`;

export const LoginBox = styled.div`
    width: 450px;
`;