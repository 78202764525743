import React, {useState} from 'react';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {useNavigate, useLocation} from 'react-router-dom';

import {Container, MenuCollapse, CollapsedMenu} from './styles';
import {Button, Menu, MenuItem, Tag, Tooltip, Position, Popover} from "@blueprintjs/core";
import {useDispatch, useSelector,} from 'react-redux';

import sidebar from '~/Config/Routes';

import collect from 'collect.js';
import {savePreference} from "~/@newestapps/Redux/actions/preferences";

export default function Sidebar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const acl = useSelector(state => state.ui.acl);
    const sidebarCollapsed = useSelector(state => state.preferences.sidebarCollapsed);
    const sidebarCollapsedRequested = useSelector(state => state.ui.requestSidebarCollapsed);

    const menu = collect(sidebar);

    const canShowItem = (item) => {
        if (item && item.acl && Array.isArray(item.acl)) {

            for (let i = 0; i < item.acl.length; i++) {
                const a = acl[item.acl[i]];

                if (a === true) {
                    return true;
                }
            }


            return false;
        }

        return true;
    };

    const onSelect = (selected) => {
        menu.each(firstItem => {
            if (selected.indexOf(firstItem.key) >= 0) {
                if (firstItem.menu) {
                    firstItem.menu.forEach(subItem => {
                        if (subItem.key === selected) {
                            if (subItem.path) {
                                navigate(subItem.path);
                            }
                        }
                    });

                } else {
                    if (firstItem.path) {
                        navigate(firstItem.path);
                    }
                }
            }
        });
    };

    const NavItem = ({children, noTooltip = false, subItem = false, item: {key, label, icon, path, isActive = false, ...itemRest}, ...rest}) => {
        if ((sidebarCollapsedRequested === true || sidebarCollapsed) && !subItem) {
            return (
                <Tooltip content={label} position={Position.RIGHT} disabled={noTooltip}>
                    <Button
                        large
                        minimal
                        icon={icon}
                        active={isActive ? isActive : location.pathname === path}
                        onClick={() => onSelect(key)}
                        style={{marginBottom: 3,}}
                        {...itemRest}
                        {...rest}
                    >
                        {children}
                    </Button>
                </Tooltip>
            )
        }

        return (
            <MenuItem
                style={{fontSize: 14}}
                text={label}
                icon={icon}
                active={isActive ? isActive : location.pathname === path}
                onClick={() => onSelect(key)}
                {...itemRest}
                {...rest}
            >
                {children}
            </MenuItem>
        );
    };

    const HeaderItem = ({subItem, item: {label, icon, ...itemRest}}) => {
        if ((sidebarCollapsedRequested === true || sidebarCollapsed) && !subItem) {
            return (<React.Fragment/>);
        }

        return <MenuItem
            style={{fontSize: 13, cursor: 'normal'}}
            text={label}
            icon={icon}
            disabled
            {...itemRest}
        />
    };

    return (
        <Container collapsed={sidebarCollapsed === true}>
            {(sidebarCollapsedRequested === true || sidebarCollapsed) ? (
                <CollapsedMenu style={{width: 50,}}>
                    {menu.map(item => (
                        (canShowItem(item) ? (
                            item.type ? (
                                item.type === 'header' ? (
                                    <HeaderItem item={item}/>
                                ) : null
                            ) : (
                                item.screen ? (
                                    !item.menu ? (
                                        <NavItem item={item}/>
                                    ) : (
                                        <Popover
                                            position={Position.RIGHT}
                                            content={(
                                                <Menu>
                                                    {item.menu.map(subItem => (
                                                        subItem.type && subItem.type === 'header' ? (
                                                            <HeaderItem item={subItem} subItem/>
                                                        ) : (
                                                            <NavItem item={subItem} noTooltip subItem/>
                                                        )
                                                    ))}
                                                </Menu>
                                            )}>
                                            <NavItem item={item} noTooltip/>
                                        </Popover>
                                    )
                                ) : <NavItem item={item} intent={'danger'}
                                             label={<Tag intent={'danger'}>Indisponível</Tag>}
                                             disabled/>

                            )
                        ) : null)
                    ))}

                </CollapsedMenu>
            ) : (
                <Menu>
                    {menu.map(item => (
                        (canShowItem(item) ? (
                            item.type ? (
                                item.type === 'header' ? (
                                    <HeaderItem item={item}/>
                                ) : null
                            ) : (
                                item.screen ? (
                                    !item.menu ? (
                                        <NavItem item={item}/>
                                    ) : (
                                        <NavItem item={item}>
                                            {item.menu.map(subItem => (
                                                <NavItem item={subItem}/>
                                            ))}
                                        </NavItem>
                                    )
                                ) : <NavItem item={item} intent={'danger'}
                                             label={<Tag intent={'danger'}>Indisponível</Tag>}
                                             disabled/>

                            )
                        ) : null)
                    ))}

                </Menu>
            )}

            <MenuCollapse
                onClick={() => dispatch(savePreference('sidebarCollapsed', !sidebarCollapsed))}
                collapsed={(sidebarCollapsedRequested === true || sidebarCollapsed === true)}
            />
        </Container>
    );
}

