import fluentUI from './fluentui';

export const Palette = {
  primary: "#303e49",
  secondary: "#333333",
  sectionHeader: "#666666",
  textContrast: "#FFFFFF"
}; 

export default {
  logo: "/AcheiCorridas.svg",
  logoOriginal: "/AcheiCorridas.svg",
  logoLogin: "/AcheiCorridas.svg",
  colors: {
    ...Palette,

    text: "#000000",
    hint: "#9A9A9A",
    surface: "#FFFFFF",
    windowBackground: "#F3F3F3",

    fluentUI: fluentUI,

    contrast: {
      textPrimary: Palette.textContrast
    }
  },
};
