import React from 'react';
import { Container } from './styles';
import styled from 'styled-components';

import { Button as BPButton } from '@blueprintjs/core';
import {useSelector} from "react-redux";

export default function Actions(props) {
    const sidebarCollapsed = useSelector(state => state.preferences.sidebarCollapsed);
    const sidebarCollapsedRequested = useSelector(state => state.ui.requestSidebarCollapsed);

    return (
        <Container style={props.style} collapsed={(sidebarCollapsedRequested === true || sidebarCollapsed)}>
            {props.children}
        </Container>
    );

}

Actions.Button = styled(BPButton).attrs(attrs => ({
    outlined: true,
}))`
    border-radius: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    border-top:0 !important;
    border-bottom:0 !important;
    ${props => props.end ? 'border-right:0 !important;' : 'border-left:0 !important;'}
`;

Actions.ButtonIcon = styled(Actions.Button).attrs(attrs => ({
    outlined: true,
}))`
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 20px;
    padding-left: 20px;
`;
