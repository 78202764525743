const INITIAL_STATE = {
  themeName: "newestapps",
  accessToken: null
};

export default function preferences(state = INITIAL_STATE, action) {
  // switch (action.type) {
  //     case 'SHOW_TICKETS_OVERLAY':
  //         return {...state, showingTicketsOverlay: true, tickets: action.tickets};
  // }

  switch (action.type) {
    case "SAVE_PREFERENCE":
      return { ...state, [action.key]: action.value };

    case "LOGOUT":
      return { ...state, accessToken: null };
  }

  return state;
}
