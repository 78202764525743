import React, { useEffect, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

import Api from "~/Api";
import { Overlay, Button, Classes, Tag, H3, Divider, Toast, ProgressBar, Position, Toaster } from "@blueprintjs/core";

export default function AppBackendHandler() {
    const { enqueueSnackbar } = useSnackbar();
    const { colors } = useContext(ThemeContext);
    const [validationErrors, setValidationErrors] = useState(null);
    const [permissionDenied, setPermissionDenied] = useState(null);
    const [uploadPercentage, setUploadPercentage] = useState(null);

    useEffect(() => {

        Api.listenResponseEvent('onValidationError', (response, message, errors) => {
            enqueueSnackbar(message, { variant: 'info' });
            setValidationErrors({ errors, terms: response.data.terms });
            setUploadPercentage(null);
        });

        Api.listenResponseEvent('onUnauthorized', (response, message) => {
            setPermissionDenied(message);
            setUploadPercentage(null);
        });

        Api.listenRequestEvent('onUploadProgress', (request, progressEvent, percentage) => {
            console.log(progressEvent);
            setUploadPercentage(percentage);

            if (percentage >= 100) {
                setTimeout(() => {
                    setUploadPercentage(null);
                }, 1000);
            }
        });

    }, []);

    return (
        <React.Fragment>

            <Toaster position={Position.TOP} maxToasts={10} canEscapeKeyClear={false} usePortal>
                {uploadPercentage !== null ? (
                    <Toast onDismiss={() => {setUploadPercentage(null)}} icon={'cloud-upload'} timeout={0} message={(
                        <div style={{ paddingTop: 5, marginRight: -40, zIndex: 999, position: 'absolute', left:40, right: 50, }}>
                            <div style={{ position: 'absolute', right: 0, height: 25, width: 30, top: 0, backgroundColor: 'white' }}></div>
                            <ProgressBar stripes={uploadPercentage >= 100} intent={'danger'} value={uploadPercentage / 100} animate  />
                        </div>
                    )} />
                ) : null}
            </Toaster>

            <Overlay onClose={() => setValidationErrors(null)} className={Classes.OVERLAY_SCROLL_CONTAINER} isOpen={validationErrors !== null}>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}>
                    <div className={classNames(
                        Classes.CARD,
                        Classes.ELEVATION_4,
                    )} style={{ minWidth: 400, maxWidth: '50%' }}>
                        <H3 style={{ color: colors.primary }}>Erros encontrados!</H3>

                        <Divider style={{ marginBottom: 15 }} />

                        {validationErrors ? (
                            Object.keys(validationErrors.errors).map(e => {

                                return (
                                    <div>
                                        {validationErrors.errors[e].map(eitem => (
                                            <p style={{ paddingBottom: 10, }}>
                                                <Tag>{validationErrors.terms[e] ? validationErrors.terms[e] : e}</Tag> {eitem}
                                            </p>
                                        ))}
                                    </div>
                                );
                            })
                        ) : null}

                        <br />

                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={'danger'} onClick={() => setValidationErrors(null)} style={{ margin: "" }}>
                                OK. Obrigado
            </Button>
                        </div>
                    </div>
                </div>
            </Overlay>

            <Overlay onClose={() => setPermissionDenied(null)} className={Classes.OVERLAY_SCROLL_CONTAINER} isOpen={permissionDenied !== null}>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}>
                    <div className={classNames(
                        Classes.CARD,
                        Classes.ELEVATION_4,
                    )} style={{ minWidth: 400, maxWidth: '50%', backgroundColor: colors.primary }}>
                        <H3 style={{ color: colors.textContrast }}>Permissão Negada</H3>

                        <Divider style={{ marginBottom: 15 }} />

                        <p style={{ color: colors.textContrast }}>
                            {permissionDenied}
                        </p>


                        <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ marginTop: 25, }}>
                            <Button intent={'none'} minimal onClick={() => setPermissionDenied(null)} style={{ color: colors.textContrast }}>
                                Fechar
                </Button>
                        </div>
                    </div>
                </div>
            </Overlay>

        </React.Fragment>
    );
}
