import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "styled-components";
import { isEmpty } from "lodash";

import { Logo, Content } from "~/Components";
import {
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Breadcrumbs,
  Breadcrumb,
  NavbarDivider,
  Tag,
  Alignment,
  Button,
} from "@blueprintjs/core";
import ACL from "~/@newestapps/ACL";

import { savePreference } from "~/@newestapps/Redux/actions/preferences";

import Api from "~/Api";
import { useSnackbar } from "notistack";

import appConfig from '~/Config/App';

export default function Header() {
  const breadcrumb = useSelector((state) => state.ui.breadcrumb);
  const user = useSelector((state) => state.ui.user);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [openingClosing, setOpeningClosing] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  const [company, setCompany] = useState(
    user && user.company ? user.company : null
  );

  // function switchOpenedCompany(opened) {
  //   setOpeningClosing(true);

  //   Api.Companies.OpenClose(opened).then((result) => {

  //     const message = result.data.data.message;
  //     const isOpen = result.data.data.is_open;

  //     Api.Users.Me().then((meme) => {
  //       const user = meme.data.data;
  //       enqueueSnackbar(message, { variant: isOpen ? "success" : "warning" });

  //       setCompany(user.company);

  //       dispatch(savePreference("user", user));
  //       setOpeningClosing(false);
  //     });
  //   });
  // }

  function signOut() {
      setSigningOut(true);
      // setOpeningClosing(true);
      // Api.Companies.OpenClose(false).then(() => {
      //   Api.Logout();

      //   setTimeout(() => {
          dispatch(savePreference("accessToken", null));
          dispatch(savePreference("user", null));
      //   }, 500);
      // });
  }

  return (
    <Navbar fixedToTop style={{ paddingLeft: 15, zIndex: 15, }}>
      <Content>
        <NavbarGroup>
          <NavbarHeading>
            <Logo style={{height: 35, marginTop: 2, marginLeft: -20}} />
          </NavbarHeading>

          {/**{acl && ACL.allow("admin") ? (
            <NavbarHeading>
              <Tag large minimal>
                Administrativo
              </Tag>
            </NavbarHeading>
          ) : (
            <NavbarHeading>
              <Tag large intent={"primary"}>
                {user.company.display_name}
              </Tag>
            </NavbarHeading>
          )} */}

            <NavbarHeading>
              <Tag large minimal>
                {appConfig.name}
              </Tag>
            </NavbarHeading>
{/**
          {breadcrumb.length > 0 ? (
            <NavbarDivider style={{ marginRight: 20 }} />
          ) : null}

          <Breadcrumbs
            items={breadcrumb}
            currentBreadcrumbRenderer={({ text, ...restProps }) => (
              <Breadcrumb {...restProps}>{text} </Breadcrumb>
            )}
          /> */}
        </NavbarGroup>

        {user ? (
          <NavbarGroup align={Alignment.RIGHT}>
            <Button
              style={{ marginLeft: 40 }}
              onClick={signOut}
              loading={signingOut}
            >
              Sair
            </Button>
          </NavbarGroup>
        ) : null}

      </Content>
    </Navbar>
  );
}
