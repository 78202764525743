import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

// Reducers
import reducers from './reducers';

const persistConfig = {
    key: '@newestapps',
    storage: storage,
    whitelist: ['preferences'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const Store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(thunkMiddleware), // loggerMiddleware
    ),
);

const Persistor = persistStore(Store);

export {Store, Persistor};

