import React, {useEffect, useRef, useState} from "react";
import {useField} from "@unform/core";
import {TimePicker, TimePrecision} from "@blueprintjs/datetime";
import moment from 'moment-timezone';

export default function Component({name, seconds = false, useAmPm = false, ...rest}) {
    const inputRef = useRef();
    const {fieldName, registerField, defaultValue} = useField(name);
    const [value, setValue] = useState(defaultValue ? defaultValue : moment().toDate());

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: "props.value",
            getValue: ref => {
                // console.log(moment(ref.current.props.value).format(!seconds ? 'HH:mm' : 'HH:mm:ss'));
                return moment(ref.current.props.value).format(!seconds ? 'HH:mm' : 'HH:mm:ss');
            },
            setValue: val => {
                setValue(moment(val).toDate());
            }
        });
    }, [fieldName, registerField]);

    return (
        <TimePicker
            fill
            ref={inputRef}
            onChange={(val) => {
                // console.log(val);
                setValue(val);
            }}
            value={value}
            precision={(seconds ? TimePrecision.SECOND : TimePrecision.MINUTE)}
            useAmPm={useAmPm}
            selectAllOnFocus
            {...rest}
        />
    );
}
