import { combineReducers } from "redux";
import appReducers from '~/Redux/reducers';

// Reducers
import ui from "./ui";
import preferences from "./preferences";

export default combineReducers({
    ui,
    preferences,

    ...appReducers
});
