import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./App.scss"; 
import "codemirror/lib/codemirror.css";

import "~/@newestapps/Theme/Corporate/blueprintjs.scss";

import configAC from '~/Config/AcheiCorridas';

// Service 
import { TimeService } from "~/@newestapps/Services";
import { AcheiCorridas } from 'acheicorridas';

// Initialize timezone setup
TimeService.setup(); 

AcheiCorridas.Initialize(configAC);

ReactDOM.render(<App />, document.getElementById("root"));
