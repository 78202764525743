/* eslint-disable default-case */
import {Corporate as AppTheme} from '~/@newestapps/Theme';

let STATUS_BAR_STYLE = {
  statusBarColor: null,
  statusBarContent: null
};

let LOADING_OVERLAY = {
  visible: false,
  text: "Processando..."
};

const INITIAL_STATE = {
  ...STATUS_BAR_STYLE,

  theme: AppTheme,

  booted: false,
  acl: {},
  user: {},
  echo: null,

  snackbar: {
    visible: false,
    message: '',
    params: {}
  },

  breadcrumb: [],

  loadingOverlay: LOADING_OVERLAY,

  dialogs: {
  },

  globalImageEditor: null,

  requestSidebarCollapsed: null, // if null uses from saved preferences
};

export default function ui(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SHOW_LOADING_OVERLAY":
      return {
        ...state,
        loadingOverlay: {
          visible: true,
          text: action.text ? action.text : "Processando..."
        }
      };

    case "HIDE_LOADING_OVERLAY":
      return {
        ...state,
        loadingOverlay: {
          visible: false,
          text: "Processando..."
        }
      };

    case "BOOT":
      const animationIntro = document.getElementById('welcome_bemvindo_bienvenue_bienvenido_willkommen');

      setTimeout(() => {
        if (animationIntro && animationIntro.classList) {
          animationIntro.classList.remove('infinite');
          animationIntro.classList.add('animated', 'fadeOut');
          animationIntro.addEventListener('animationend', function () {
            animationIntro.remove();
          });
        }
      }, 800);

      return {
        ...state,
        booted: true,
      };

    case "ACLS":
      return {
        ...state,
        acl: action.acls,
      };

    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

    case "SET_WEBSOCKET":
      return {
        ...state,
        echo: action.echo,
      };

    case "SET_SIDEBAR_COLLAPSED":
      return {
        ...state,
        requestSidebarCollapsed: action.collapsed,
      };

    case "SET_BREADCRUMB":
      if (action.extend) {
        const updated = action.clear ? [] : state.breadcrumb;

        action.breadcrumb.forEach(b => {
          updated.push(b);
        });

        return {
          ...state,
          breadcrumb: updated,
        };
      } else {
        return {
          ...state,
          breadcrumb: action.breadcrumb,
        };
      }

    case "SHOW_DIALOG":
      return {
        ...state, dialogs: {
          ...state.dialogs,
          [action.dialog]: {
            open: true,
            params: action.params,
          }
        }
      };

    case "OPEN_GLOBAL_IMAGE_EDITOR":
      return {
        ...state,
        globalImageEditor: action.image
      };

    case "CLOSE_DIALOG":
      return {
        ...state, dialogs: {
          ...state.dialogs,
          [action.dialog]: {
            open: false,
            params: {},
          }
        }
      };

      return { ...state, snackbar: { ...action } };
  }

  return state;
}
