import {Form} from '@unform/web';

import Select from "./Select";
import SelectSimple from "./SelectSimple";
import Switch from "./Switch";
import Checkbox from "./Checkbox";
import SelectResource from "./SelectResource";
import Label from "./Label";
import Input from "./Input";
import InputMask from "./InputMask";
import FileInput from "./FileInput";
import ImageInput from "./ImageInput";
import MoneyInput from "./MoneyInput";
import NumericInput from "./NumericInput";
import RadioGroup from "./RadioGroup";
import TagInput from "./TagInput";
import TextArea from "./TextArea";
import CodeInput from "./CodeInput";
import Slider from "./Slider";
import DateInput from "./DateInput";
import TimeInput from "./TimeInput";
import DateRangeInput from "./DateRangeInput";

import {FormGroup} from '@blueprintjs/core';

export {Form, FormGroup, Label};
export {
    Checkbox,
    Switch,
    SelectResource,
    SelectSimple,
    Select,
    Input,
    InputMask,
    FileInput,
    ImageInput,
    MoneyInput,
    NumericInput,
    RadioGroup,
    TagInput,
    TextArea,
    CodeInput,
    Slider,
    DateInput,
    DateRangeInput,
    TimeInput,
};
