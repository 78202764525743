import React, {Suspense, useEffect, useState} from "react";
import {Spinner} from "@blueprintjs/core";

export default function SelectResource(props) {
    const RenderedSelect = React.lazy(() => import('~/@newestapps/Form/SelectProvider.js'));
    const isDomain = props.target.indexOf('@domains') >= 0;

    const [domainConfig, setDomainConfig] = useState(null);

    useEffect(() => {
        if (props.target)
            getDomainConfig();

    }, [props.target]);

    const getDomainConfig = () => {
        import('~/' + props.target + '/index.js').then(rrr => {
            setDomainConfig(rrr.Config);
        });
    };

    return (
        <React.Fragment>
            <Suspense fallback={<Spinner size={25}/>}>
                {!isDomain || (isDomain && domainConfig !== null) ? (
                    <RenderedSelect
                        {...props}
                        domain={props.domain || isDomain}
                        config={!isDomain ? (
                            require('~/@app/Select/' + props.target + '/config.json')
                        ) : (
                            domainConfig
                        )}/>
                ) : null}
            </Suspense>
        </React.Fragment>
    );

}
