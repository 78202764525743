import React, { Suspense } from "react";
import { Routes as Router, Route, Redirect } from "react-router-dom";

// Screens
import LoginScreen from "~/@acheicorridas/Screens/LoginScreen";
import OAuthSigninScreen from "~/@acheicorridas/Screens/OAuthSigninScreen";

export default function RoutesAuth() {
  return (
      <Router>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/authenticate" element={<OAuthSigninScreen />} />
        <Redirect from="*" to="/" />
      </Router>
  );
}
